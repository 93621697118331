import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export var firebaseConfig = {
  apiKey: "AIzaSyCuaZ_C2C8eZORQtUGF0hHPxhCYu2wikNQ",
  authDomain: "homebrewcoffees-90872.firebaseapp.com",
  projectId: "homebrewcoffees-90872",
  storageBucket: "homebrewcoffees-90872.appspot.com",
  messagingSenderId: "730513595780",
  appId: "1:730513595780:web:41e207806fde4ef4abd1d1",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();

export default firebase;
