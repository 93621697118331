import { db } from "../../config/fbConfig";

const fetchProductsSuccess = (products) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products,
});

// fetch products
export const fetchProducts = (products) => {
  return async (dispatch) => {
    const productsRes = await db.collection("products").get();
    let products = [];
    productsRes.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id;
      products.push(data);
    });
    dispatch(fetchProductsSuccess(products));
  };
};

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
