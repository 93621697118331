import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";

// home page
const Home = lazy(() => import("./pages/home"));

// blog page
const BlogDetails = lazy(() => import("./pages/blog"));

// other pages
const About = lazy(() => import("./pages/others/About"));
const Contact = lazy(() => import("./pages/others/Contact"));
const Cart = lazy(() => import("./pages/others/Cart"));
const Checkout = lazy(() => import("./pages/others/Checkout"));
const NotFound = lazy(() => import("./pages/others/NotFound"));
const sanchari = lazy(() => import("./pages/others/sanchari"));
const SanchariCart = lazy(() => import("./pages/others/sanchariCart"));

const App = (props) => {
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json"),
        },
      })
    );
  });

  return (
    <ToastProvider placement="bottom-center">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={Home}
                />

                {/* Homepage */}
                <Route
                  path={process.env.PUBLIC_URL + "/home"}
                  component={Home}
                />

                {/* Blog pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/blog/:id"}
                  component={BlogDetails}
                />

                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product/:proId"}
                  component={NotFound}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/sanchari"}
                  component={sanchari}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/sanchariCart"}
                  component={SanchariCart}
                />

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));
